import { Button, Container, Grid } from "@mui/material"
import StarIcon from '@mui/icons-material/Star';

export const HeaderBar = () => {
    return (
        <div className="greenBG">
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                padding: '5px',
                justifyContent: 'space-between'
            }}>
                <div>&nbsp;</div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center', 
                    alignItems: 'center',
                    fontWeight: 'bold'
                }} className="resizeText">
                    <div>GIVE US A CALL! <a href="tel:5127149663" style={{color: '#fff'}}>(512) 714-9663</a></div>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{padding: '10px', fontWeight: 'bold'}} className="resizeText">Follow Us</div>
                    <InstagramLink />
                    <FacebookLink />
                </div>
            </div>
        </div>
    )
}

export const InstagramLink = () => (
    <div style={{padding: '5px'}}>
        <a href="https://www.instagram.com/woof_atx/" target="_blank" rel="noreferrer">
            <img src={process.env.PUBLIC_URL + "/images/whiteinstagram.webp"}
                style={{width: '30px', height: '30px'}} alt="Instagram" />
        </a>
    </div>
)

export const FacebookLink = () => (
    <div style={{padding: '5px'}}>
        <a href="https://www.facebook.com/people/WOOF-Pet-Care/61558819301392/" target="_blank" rel="noreferrer">
            <img src={process.env.PUBLIC_URL + "/images/whitefacebook.webp"}
                style={{width: '30px', height: '30px'}} alt="Facebook" />
        </a>
    </div>
)

export const WinnerBar = () => {
    return (
        <div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '120px',
                flexWrap: 'wrap'
            }}>
                <div style={{
                    display: 'flex', justifyContent: 'center'
                }}>
                    <img src={process.env.PUBLIC_URL + "/images/2024winner.webp"} alt="2024 Winner" style={{height: '75px'}} />
                </div> 
                <div style={{
                    display: 'flex', justifyContent: 'center'
                }}>
                    <img src={process.env.PUBLIC_URL + "/images/longhorn100.webp"} alt="Longhorn 100 Winner" style={{height: '75px'}}/>
                </div>
            </div>
        </div>
    )
}

export const Testimonials = () => {

    return (
        <Container>
            <Grid container style={{fontWeight: 'bold', paddingTop: '50px', paddingBottom: '50px'}}>
                <Grid item xs={12} md={6}>
                    <div style={{
                        padding: '20px',
                        textAlign: 'center',
                        minHeight: '150px',
                    }}>
                        If you're looking for a dog walking & pet sitting company that
                        truly cares about your furry family member, then look no
                        further than WOOF atx. From the moment I contacted them,
                        I could tell that their team is made up of dog lovers who
                        understand the importance of providing personalized care to
                        each and every pup they work with.
                    </div>
                    <div style={{
                        textAlign: 'center'
                    }}>
                        <ReviewSection name={"MacKenzie Seale"} image={"/images/mckenzie.webp"} />
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div style={{
                        padding: '20px',
                        textAlign: 'center',
                        minHeight: '150px'
                    }}>
                        Oh wow. Where to start!? Woof has been a godsend— always
                        there when I need them. There was a time I needed a daily
                        walk for my pup, Wyatt, and they'd alway be there to pick him
                        up on time and take him for a stroll around beautiful Austin,
                        rain or shine! I've been in a bind more than once and needed
                        immediate assistance for Wyatt as well, and they shifted things
                        around to go over to check on him.
                    </div>
                    <div style={{
                        textAlign: 'center'
                    }}>
                        <ReviewSection name={"Jennifer Cambron"} image={"/images/jennifer.webp"} />
                    </div>
                </Grid>
                <Grid item xs={12} md={12} style={{
                    padding: '10px',
                    textAlign: 'center'
                }}>
                    <a href="https://maps.app.goo.gl/SYkGsuwkzXvbt5Pf9r" style={{color: '#183c3a'}} target="_blank" rel="noreferrer">
                        <h3>Hear From More Happy Customers Here</h3>
                    </a>
                </Grid>
            </Grid>
        </Container>
    )
}



export const ReviewSection = ({name, image}) => (
    <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '40px'
    }}> 
        <img
            style={{width: '75px', height: '75px'}} 
            src={process.env.PUBLIC_URL + image} alt="MacKenzie Seale" />
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '15px'
        }}>
            <div>
                <StarIcon style={{color: '#c7a37a'}} />
                <StarIcon style={{color: '#c7a37a'}}/>
                <StarIcon style={{color: '#c7a37a'}}/>
                <StarIcon style={{color: '#c7a37a'}}/>
                <StarIcon style={{color: '#c7a37a'}}/>
            </div>
            { name }
        </div>
    </div>
)


export const LeaveAReview = () => {
    return (
        <Grid item xs={12} md={2} style={{paddingTop: '50px', paddingBottom: '50px'}}>
            <div style={{textAlign: 'center'}}>
                <h4>Follow Us</h4>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <div style={{padding: "0 10px"}}>
                        <InstagramLink />
                    </div>
                    <div style={{padding: "0 10px"}}>
                        <FacebookLink />
                    </div>
                </div>
                <div style={{paddingTop: '20px'}}>
                    <a href="https://g.page/r/CV0c_1QBZ74yEBM/review" target="_blank" rel="noreferrer">
                        <Button style={{
                            color: '#fff',
                            border: '1px solid #fff',
                            borderRadius: '0px'
                        }}>Leave A Review</Button>
                    </a>
                </div>
            </div>
        </Grid>
    )
}