import { Container, Grid } from '@mui/material'
import React from 'react'
import { Fade } from 'react-reveal'
import { NewRenderCategory } from './RenderCategory'


export const PricingSideB = ({allItems}) => {
    return (
        <div className='greenBG'>
            <Container>
                <Fade>
                <div style={{paddingTop: '40px'}}>
                    <Grid container>
                        <Grid item xs={12} md={7}>
                            <div style={{fontSize: '2rem', fontWeight: 'bold', fontFamily: 'Times New Roman'}} className='goldText padTen'>Pet Sitting</div>
                            <div className='padTen RobotoFont' style={{fontSize: '0.9rem'}}>
                                Our Pet Sitters provide the comfort of knowing that even when you are away, your pet will feel right at home by having someone to keep them company, feed 
                                them, and sleep with them, just as if you were home. Overnight Stays can be in your home or our sitter's home, and include all walks and food.
                            </div>

                            <div className='goldText'>
                                <NewRenderCategory category={"Pet Sitting"} allItems={allItems} darkText={false}  />
                            </div>
                            <div className='goldText'>
                                <h2 className='padLTen'>Cat Visits</h2>
                                <NewRenderCategory category={"Cat Visits"} allItems={allItems} darkText={false}  />
                            </div>
                            <div className='goldText' style={{marginBottom: '100px'}}>
                                <h2 className='padLTen'>Overnight Care</h2>
                                <NewRenderCategory category={"Overnight Care"} allItems={allItems} darkText={false}  />
                                <div style={{
                                    display: 'flex',
                                    fontSize: '0.95rem',
                                    justifyContent: 'flex-end',
                                    marginTop: '10px',
                                    color: '#fff'
                                }}>* 30% Deposit Required at time of booking </div>
                            </div>
      
                        </Grid>
                        <Grid item xs={12} md={5} style={{position: "relative", minHeight: '400px'}}>
                            <img src={process.env.PUBLIC_URL + '/images/dog_hero_chi.webp'} alt="dog hero" style={{maxWidth: '100%', padding: 0, margin: 0, position: 'absolute', bottom: 0}} />
                        </Grid>
                    </Grid>
                </div>
                </Fade>
            </Container>
        </div>
    )
}

