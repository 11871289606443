import { Button, Container, useTheme } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { Fade } from 'react-reveal'
import { PricingSideB } from './subviews/PricingSideB'
import { PricingSideA } from './subviews/PricingSideA'
import { Grid } from '@mui/material'
import BalanceIcon from '@mui/icons-material/Balance';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PeopleIcon from '@mui/icons-material/People';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { ArrowForwardIos } from '@mui/icons-material'
import { OverlayAnim } from './Overlay'
import { ContactForm } from './subviews/ContactForm'
import { NewRenderCategory, WoofNewPricing } from './subviews/RenderCategory'
import { LeaveAReview, Testimonials, WinnerBar } from '../components/ExtraComponents'
import { useLocation, useNavigate } from 'react-router-dom'
import { Navbar, OnClickSingleNav, pages } from '../components/Navbar'


export const SinglePageRoot = () => {
    const [ displayedSplash, setSplash ] = useState(false)

    const location = useLocation();

    // get id and scroll to position
    useEffect(() => {
        if (displayedSplash) {
            var element = document.getElementById(location.hash.replace("#", ""));
            if (element === undefined || element === null) return 
            
            // scroll 
            window.scroll({top: element.offsetTop - 150, behavior: 'smooth'});
        }
    }, [location, displayedSplash])

    useEffect(() => {
        // load all items on startup
        setTimeout(() => {
            setSplash(true)
        }, 3000) // transfer to main
    }, [])

    // theme and footer
    const curTheme = useTheme();


    // video player
    useEffect(() => {
        const videoPlayer = document.querySelector('videoPlayer');
        if (videoPlayer !== null && displayedSplash) {
            videoPlayer.play()
        }
    }, [displayedSplash]);

    // items
    const woofAllItems = WoofNewPricing;

    return (
        <div id="home">
            { /*/********************* OVERLAY ANIMATIOPN *********************/}
            <OverlayAnim displayedSplash={displayedSplash} />
            { /*/********************* END OVERLAY ANIMATION *********************/}


            { /*/********************* NAVIGATION BAR *********************/}
            <div>
                <div style={{display: displayedSplash ? 'block' : 'none'}}>
                    <Navbar />
                    { /*/********************* END NAVBAR *********************/}

                    <div>
                        <header className='headerSection' style={{position: 'relative', overflow: 'hidden', marginBottom: '0px', paddingBottom: '0px'}}>
                            <div style={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, zIndex: 1, overflow: 'hidden'}}>
                                <video 
                                    //ref={videoRef}
                                    id="videoPlayer"
                                    loop playsInline autoPlay muted 
                                    preload="none" className='dynVid'> 
                                    <source type="video/mp4" src={`${process.env.PUBLIC_URL}/images/headervideo.mp4`} />
                                </video>
                            </div>
                            { /*/********************* END HEADER VIDEO *********************/}


                            <div style={{zIndex: 9, display: 'flex', flexDirection: 'column', justifyContent: 'center', flexGrow: 1, position: 'relative'}}>
                                <div style={{padding: '15px', color: '#FFF'}}>
                                    <Fade>
                                    <Container style={{lineHeight: '2rem'}}>
                                        <h1 style={{fontSize: '3rem', lineHeight: '2.7rem'}}>PET CARE, ELEVATED</h1>
                                        <h4 className='RobotoFont'>Professional Dog Walking, Overnight Care, & Pet Chauffeur Services.</h4>
                                        <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                            <div className='padTen'>
                                                <a href={"https://www.timetopet.com/portal/woof/create-account"} className='destyle' key={'clientportal'} style={{borderTop: `1px solid ${curTheme.primary}`}}>
                                                <Button variant='contained' style={{backgroundColor: '#183c3a', borderRadius: '0px', textTransform: 'none'}}>Become A Member</Button>
                                                </a>
                                            </div>
                                            <div className='padTen'>
                                                <a href={"https://www.timetopet.com/portal/woof/"} className='destyle' key={'clientportal'} style={{borderTop: `1px solid ${curTheme.primary}`}}>
                                                <Button variant='contained' style={{backgroundColor: '#183c3a', borderRadius: '0px', textTransform: 'none'}}>Client Portal</Button>
                                                </a>
                                            </div>
                                        </div>
                                    </Container>
                                    </Fade>
                                </div>
                            </div>
                        </header>
                    </div>
                    { /*/********************* END HEADER  *********************/}

                    <WinnerBar />
                        
                        { /*/********************* About Us Section *********************/}
                    <div id="about">
                        <div>
                            <div className='greenBG'>
                            <Container style={{paddingBottom: '70px', paddingTop: '50px'}}>
                                <Grid container spacing={2} rowSpacing={2}>
                                    <Grid item xs={12} md={7} style={{display: 'flex', flexDirection: 'column', padding: '20px', flexGrow: 1}}>
                                        <div style={{justifyContent: 'space-between', display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                                            <div>
                                                <div className='tan goldText' style={{fontSize: '3rem', fontWeight: 'bold'}}>The Woof Quality Standard</div>
                                                <p className='RobotoFont smallGold' style={{fontSize: '0.9rem'}}>
                                                    Woof was founded with the mission to bring a higher standard of quality in the pet care industry.
                                                    Gone are the days of searching through pet-sitting apps, hoping that your walker will be capable and honest.
                                                    We believe in the value of knowing your pet is with a professional, highly trained, and trustworthy pet sitter.
                                                </p>
                                                <div className='tan goldText' style={{fontSize: '2rem', fontWeight: 'bold', fontStyle: 'italic'}}>"We Believe in the value of knowing your pet is with a professional, highly trained, and trustworthy pet sitter"</div>
                                            </div>
                                            <img src={process.env.PUBLIC_URL + '/images/doggold.webp'} alt="dog care" style={{maxWidth: '150px', marginTop: '20px'}} />

                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <Grid container >
                                            <Grid item xs={12} md={6} style={{display: 'flex', padding: 0}}>
                                                <Fade>
                                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                                        <div style={{height: '120px', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column'}}>
                                                            <div className='iconStyle'><BalanceIcon fontSize='large' htmlColor='#bf9e77' style={{height: '30px'}} /></div>
                                                            <div className='headerStyle smallHead'>Insured & Bonded</div>
                                                        </div>
                                                        <div className='textStyle RobotoFont smallGold'>Woof provides peace of mind by being fully bonded and insured, exceeding industry standards.</div>
                                                    </div>
                                                </Fade>
                                            </Grid>
                                            <Grid item xs={12} md={6} style={{display: 'flex', padding: 0}}>
                                                <Fade>
                                                    <div style={{display: 'flex',  flexDirection: 'column'}}>
                                                        <div style={{height: '120px', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column'}}>
                                                            <div className='iconStyle'><FavoriteIcon fontSize='large' htmlColor='#bf9e77' style={{height: '30px'}} /></div>
                                                            <div className='headerStyle smallHead'>CPR & First Aid Certified</div>
                                                        </div>
                                                        <div className='textStyle RobotoFont smallGold'>Woof requires every team member to be certified in pet first aid and CPR, and must maintain their certification by continuing their education.</div>
                                                    </div>
                                                </Fade> 
                                            </Grid>
                                            <Grid item xs={12} md={6} style={{display: 'flex',  padding: 0}}>
                                                <Fade>
                                                    <div style={{display: 'flex',  flexDirection: 'column', flexGrow: 1}}>
                                                        <div style={{height: '120px', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column'}}>
                                                            <div className='iconStyle'><PeopleIcon fontSize='large' htmlColor='#bf9e77' style={{height: '30px'}}/></div>
                                                            <div className='headerStyle smallHead'>Hand-Picked Staff</div>
                                                        </div>
                                                        <div className='textStyle RobotoFont smallGold'>Woof has carefully selected each team member, requiring a track record of professional experience in the pet care industry, as well as understanding of animal behavior.</div>
                                                    </div>
                                                </Fade>
                                            </Grid>
                                            <Grid item xs={12} md={6} style={{display: 'flex',  padding: 0}}>
                                                <Fade>
                                                    <div style={{display: 'flex',  flexDirection: 'column'}}>
                                                        <div style={{height: '120px', justifyContent: 'flex-start', display: 'flex', flexDirection: 'column'}}>
                                                            <div className='iconStyle'><PhoneAndroidIcon fontSize='large' htmlColor='#bf9e77' style={{height: '30px'}}/></div>
                                                            <div className='headerStyle smallHead'>Web Portal & Mobile App</div>
                                                        </div>
                                                        <div className='textStyle RobotoFont smallGold'>Our web portal and mobile app bring the convenience of being able to check scheduled appointments, receive walk report cards, and easily communicate with your walker.</div>
                                                    </div>
                                                </Fade>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Container>
                            </div>

                            <div id="services">
                                <PricingSideA allItems={woofAllItems} />
                                <PricingSideB allItems={woofAllItems} />
                            </div> 
                        </div>

                    </div>
                    { /*/********************* END ABOUT US *********************/}

                    
                    { /*/********************* SERVICES BELOW *********************/}
                    <div className='tanBG'>
                        <Container style={{paddingTop: '100px', paddingBottom: '150px' }}>
                            <h1>Additional Services</h1>

                            <Grid container padding={2} spacing={4}>
                              

                                <Grid item md={6} xs={12}>
                                    <Fade>
                                    <h2>Pet Chauffeur</h2>
                                    <div>
                                        <NewRenderCategory category={"Pet Chauffeur"} allItems={woofAllItems} />
                                        { /* RenderCategory("Pet Chauffeur", allItems, undefined)  */}
                                    </div>
                                    </Fade>
                                </Grid>
                                <Grid item md={6} xs={0} />
                                <Grid item xs={12} md={12}>
                                    <h2>Additional Fees</h2>
                                </Grid>

                                <Fade>
                                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                    { woofAllItems.filter(item => item.Category === "Additional Fees").map((item) => {
                                        return (
                                            <div style={{display: 'flex', color: '#fff', alignItems: 'center', justifyContent: 'center', minWidth: '50%', padding: '0 0 0'}} className='additionalDyn' key={`${item.Item}-${makeid(6)}`}>
                                                <div style={{fontSize: '1rem', color: '#000', marginLeft: '32px', marginBottom: '5px'}}>{ item.Item }</div>
                                                <div className='lineSepW' style={{padding: '0!important'}}></div>
                                                <div style={{color: '#000', fontWeight: 'bold'}}>{ item.Price }</div>
                                            </div>
                                        )
                                    })}
                                </div>
                                </Fade>
                            </Grid>
                        </Container>
                    </div>
                    { /*/********************* END SERVICES *********************/}

                    <Testimonials />
                    
                    { /*/********************* CONTACT FORM *********************/}
                    <div id="contact">
                        <ContactForm />
                    </div>
                    { /*/********************* END CONTACT *********************/}

                    
                    { /*/********************* FOOTER *********************/}
                    <FooterNew />
                </div>
            </div>
    </div>
    )
}

export const FooterNew = () => {
    const navigate = useNavigate();
    const getYear = () => {
        return new Date().getFullYear();
    }

    return (
        <div id="footer" style={{zIndex: 9}}>
            <div>
                <BecomeAMember />
            </div>
            <div className='footer' style={{backgroundColor: '#000', color: '#fff'}}>
                <Container>
                    <Grid container>
                        <Grid item xs={12} md={4} style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                            <h2 className='padTen'>WOOF Pet Care Services</h2>
                            <div className='padTen'>Serving the Downtown Austin, TX area</div>
                            <div className='padTen'><a href="tel:+15127149663" className='destyle' style={{fontWeight: 'bold', letterSpacing:"1px"}}>(512) 714-9663</a></div>
                            <div className='padTen'><a href="mailto:manager@woofatx.com" className='destyle' style={{fontWeight: 'bold', letterSpacing:"1px"}}>manager@woofpetcare.com</a></div>
                        </Grid>
                        <Grid item xs={0} md={2} />
                        <Grid item xs={12} md={4} style={{paddingTop: '50px', paddingBottom: '50px'}}>
                            <ul>
                                { pages.map(item => (
                                    <li key={item.label} onClick={() => OnClickSingleNav(item.path, navigate)}>
                                        <Button variant='contained' color='primary' style={{backgroundColor: '#000', borderRadius: '0px', padding: '0 10px 0 10px'}}>{ item.label }</Button>
                                    </li>
                                ))}
                            </ul>
                            <a href={"https://www.timetopet.com/portal/woof/"} className='destyle'>
                                <Button style={{backgroundColor: '#000', color: "#fff"}} variant='outlined'>Client Portal</Button>
                            </a>
                        </Grid>
                        <LeaveAReview />
                    </Grid>
                    <div style={{textAlign: 'center', color: '#fff9'}}>
                        &copy;  { getYear() } WoofATX LLC.
                    </div>
                </Container>
            </div>
        </div>
    )
}

const BecomeAMember = () => {
    return (
        <div className='becomeAMember' style={{zIndex: 2}}>
            <a href="https://www.timetopet.com/portal/woof/create-account" style={{color: '#fff'}}>Become a Member</a>
            <ArrowForwardIos style={{color: '#fff'}} />
        </div>
    )
}

export function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}
