import React, { useState } from 'react'
import { TextField, Alert, Container, Grid, Button } from '@mui/material'

const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
};


// CONTACT ENTRY FORM BELOW
export const ContactForm = () => {
    const [ success, setSuccess ] = useState(false)
    const [ error, setError ] = useState(undefined)

    const [ email, setEmail ] = useState("")
    const [ name, setName ] = useState("")
    const [ phone, setPhone ] = useState("")
    const [ zip, setZip ] = useState("")
    const [ message, setMessage ] = useState("")

    const clearAllVars = () => {
        setEmail("")
        setName("")
        setPhone("")
        setZip("")
        setMessage("")
    }

    // contact form entry 
    const callOutContact = async () => {
        // Contact Entry 
        try { 
            console.log("[ + ] Contact Entry")

            // check email
            if (!validateEmail(email)) {
                setError("Need a valid email")
                return
            } else if (message === "") {
                setError("Need to leave a message")
                return
            }

            // call api 
            await fetch(`/api/contactEntry`, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify( 
                    {
                        name: name,
                        email: email,
                        message: message,
                        zipcode: zip,
                        phone: phone,
                    }
                )
            }).then(res => res.json()).catch(err => {
                console.log(err)
                setError(err)
                return
            })

            setError(undefined)
            clearAllVars()
            setSuccess(true)
        } catch(e) {
            setError(e)
        }

    }


    return (
        <div style={{position: 'relative', overflow: 'hidden', minHeight: '700px'}}>
            <div className='bottomImage' style={{zIndex: -1}}>
                <video 
                    //ref={videoRef}
                    id="videoPlayer"
                    loop playsInline autoPlay muted
                    preload="none"> 
                    <source type="video/mp4" src={`${process.env.PUBLIC_URL}/images/contactmedia.mp4`} />
                </video>
            </div>


            { /* Actual Form */ }
            <Container style={{paddingTop: '100px'}}>
                <Grid container style={{zIndex: 1}}>
                    <Grid item xs={12} md={6} />
                    <Grid item xs={12} md={6}>
                        <div style={{paddingTop: '150px', padding: '20px', borderBottomLeftRadius: '50px'}} className='dynMobileBG'>
   
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flexGrow: 1}}>
                                <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flexDirection: 'column'}} className='dynMobText'>
                                    <div style={{fontWeight: 'bold', fontSize: '3rem', textShadow: '1px 1px 3px #fff'}} >Contact Us</div>
                                    <div style={{textShadow: '1px 1px 3px #fff'}} className='RobotoFont'>We can't wait to meet your pet!</div>
                                </div>
                            </div>

                            <div style={{paddingTop: '30px'}}>
                                <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                                    <TextField label="Email" variant="filled" className='flexGMob' value={email} style={{backgroundColor: '#fff', marginTop: '10px'}} onChange={(e) => setEmail(e.target.value)} />
                                    <TextField label="Name" variant="filled" className='flexGMob' value={name} style={{backgroundColor: '#fff', marginTop: '10px'}} onChange={(e) => setName(e.target.value)} />
                                </div>
                                <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                                    <TextField variant="filled" label="Phone" className='flexGMob' value={phone} style={{backgroundColor: '#fff', marginTop: '10px'}} onChange={(e) => setPhone(e.target.value)} />
                                    <TextField variant="filled" label="Zip Code" className='flexGMob' value={zip} style={{backgroundColor: '#fff', marginTop: '10px'}} onChange={(e) => setZip(e.target.value)} />
                                </div>
                                <div>
                                    <TextField variant="filled" label="Enter your message..." multiline rows={5} value={message} fullWidth style={{backgroundColor: '#fff', marginTop: '10px'}} onChange={(e) => setMessage(e.target.value)} />
                                </div>
                                <div style={{display: 'flex', justifyContent: 'flex-end', paddingTop: '10px'}}>
                                    <Button variant='contained' style={{borderRadius: '0', backgroundColor: '#000', minWidth: '250px'}} onClick={() => callOutContact()}>Submit</Button>
                                </div>
                                
                            </div>

                            { success ? (
                                <div style={{paddingTop: '20px'}}>
                                    <Alert severity='success'>Successfully Submitted</Alert>
                                </div>
                            ): null}

                            { error !== undefined ? (
                                <div style={{paddingTop: '20px'}}>
                                    <Alert severity='error'>{ error }</Alert>
                                </div>
                            ): null}

                        </div>
                    </Grid>
                </Grid>
            </Container>


            <div>
                <div style={{minHeight: '250px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    { /* 
                    <h3>Still Need Help?</h3>
                    <a href="tel:5127149663" className='destyle'>
                        <Button variant='contained' color="secondary" style={{minWidth: '200px'}}>Call Us</Button>
                    </a>
                    */}
                </div>
            </div>
        </div>
    )
}