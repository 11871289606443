import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';
import './App.css';
import { SinglePageRoot } from './pages/SinglePage';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { NotFoundPage } from './pages/404Page';

const woofTheme = createTheme({
  palette: {
    background: {
      default: '#f7f2e8',
    },
    primary: {
      main: '#183C3A',
    },
    secondary: {
      main: '#e65100',
    },
  },
  typography: {
    fontFamily: 'Times New Roman',
  },
})

function App() {

  return (
    <ThemeProvider theme={woofTheme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SinglePageRoot />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
