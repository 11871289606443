import React, { useEffect, useState } from 'react'
import Lottie from 'react-lottie';
import animationData from '../lottie/animationnew.json'
import { Fade } from 'react-reveal';

export const OverlayAnim = ({displayedSplash}) => {
    const [ fireFade, setFireFade ] = useState(false)
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
    };

    useEffect(() => {
        setTimeout(() => setFireFade(true), 2000)
    }, [])

    useEffect(() => {
        if (displayedSplash) {
            var element = document.getElementById("viewport");
            element.style.height = 0;
        }
    }, [displayedSplash])
    
    return (
        <div>
            <div id="viewport" style={{width: '100vw', height: '100vh', transition: '1s', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} className='tanBG'>
                <Lottie
                    options={defaultOptions}
                    height={300}
                    width={300}
                />
                <Fade in={fireFade}>
                    <h2 style={{marginTop: '-100px'}}>Pet Care Services</h2>
                </Fade>
            </div>
        </div>
    )
}