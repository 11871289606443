import { Container, Grid } from '@mui/material'
import React from 'react'
import { Fade } from 'react-reveal'
import { NewRenderCategory } from './RenderCategory'


export const PricingSideA = ({allItems}) => {

    return (
        <div>
            <Container>
                <div style={{paddingTop: '40px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={5} style={{position: "relative", minHeight: '400px'}}>
                            <img src={process.env.PUBLIC_URL + '/images/dog_hero_mix.webp'} alt="dog hero" style={{maxWidth: '100%', padding: 0, margin: 0, position: 'absolute', bottom: 0, left: 0}} />
                        </Grid>
                        <Grid item xs={12} md={7} className='padTen'>
                            <Fade>
                            <div style={{padding: '10px', fontSize: '2rem', fontWeight: 'bold', fontFamily: 'Times New Roman'}}>Dog Walking</div>
                            </Fade>

                            <Fade>
                            <div style={{padding: '10px', fontSize: '0.9rem'}} className='RobotoFont'>
                            Our Pet Walkers will take your pup out for a nice walk. We offer multiple lengths of time to accommodate from puppies with lots of energy, to older pups who just need to get some fresh air and potty.
                            </div>
                            </Fade>

                            <Fade>
                            <div>
                                <h2 className='padLTen'>Dog Walks</h2>
                                <NewRenderCategory category={'Dog Walks'} allItems={allItems} darkText={true} />
                                { /* RenderCategory('Dog Walks', allItems, true) */}
                            </div>
                            </Fade>
                        </Grid>
                    </Grid>


                </div>
            </Container>
        </div>


    )
}