
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { HeaderBar } from '../components/ExtraComponents';
import { Button, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const pages = [
    {label: "Home", path: "/#home", id: "homelink"},
    {label: "About Us", path: "/#about", id: "aboutlink"},
    {label: "Services", path: "/#services", id: "serviceslink"},
    {label: "Contact", path: "/#contact", id: "contactlink"},
]

export const OnClickSingleNav = (path, navigate) => {
    //var element = document.querySelector(item.path);
    //element.scrollIntoView({ behavior: 'smooth'});

    // redirect to home 
    navigate(path)
}


export const Navbar = () => {
    const navigate = useNavigate();

    const [anchorElNav, setAnchorElNav] = useState(null);
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    /* Navbar Stuff */
    const [transBG, setTransBG ] = useState(true)
    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            //subscription.unsubscribe();
            console.log("[ cleared ]")
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])

    useEffect(() => {
        if (scrollPosition > 100) {
            setTransBG(false)
        } else {
            setTransBG(true)
        }
    }, [scrollPosition])



    return (
        <AppBar style={{backgroundColor: transBG ? 'transparent' : '#000', boxShadow: 'none', transition: '0.5s'}}>
            { /** Header Bar */}
            <HeaderBar />
            
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    sx={{
                    mr: 2,
                    display: { xs: 'none', md: 'flex' },
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                    }}
                >
                    <div style={{display: 'flex', alignItems: 'center',}}>
                        <div onClick={() => {OnClickSingleNav("#home", navigate)}} className='destyle' style={{cursor: 'pointer'}}>
                            { transBG ? (
                                <img src={process.env.PUBLIC_URL + '/images/dog.webp'} alt="dog pet care"  style={{maxHeight: '70px', padding: '10px'}} />
                            ): (
                                <img src={process.env.PUBLIC_URL + '/images/doginvert.webp'} alt="dog pet care"  style={{maxHeight: '70px', padding: '10px'}} />
                            )}
                        </div>
                    </div>
                </Typography>
                <Typography
                    variant="h5"
                    noWrap
                    component="div"
                    sx={{
                    mr: 2,
                    display: { xs: 'flex', md: 'none' },
                    flexGrow: 1,
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                    }}
                >
                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row'}}>
                        <div onClick={() => {OnClickSingleNav("#home", navigate)}} className='destyle' style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                            { transBG ? (
                                <img src={process.env.PUBLIC_URL + '/images/dog.webp'} alt="dog pet care"  style={{maxWidth: '100px', padding: '10px'}} />
                            ) : (
                                <img src={process.env.PUBLIC_URL + '/images/doginvert.webp'} alt="dog pet care"  style={{maxWidth: '100px', padding: '10px'}} />
                            ) }
                        </div>
                    </div>
                </Typography>

                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end' }}>
                    <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                    >
                    <MenuIcon style={{color: transBG ? '#000' : '#fff'}} />
                    </IconButton>
                    <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                    }}
                    >
                        { /* Pages Here */ }
                        { pages.map((item) => (
                            <MenuItem key={item.label} onClick={() => {OnClickSingleNav(item.path, navigate); handleCloseNavMenu();}} id={item.id} 
                                style={{color: 'inherit'}}>
                                <Typography textAlign="center">{ item.label }</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>

                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
                { /* Pages Here */ }
                    { pages.map((item) => (
                        <div className='destyle' key={item.label} onClick={() => OnClickSingleNav(item.path, navigate)} id={item.id}
                                style={{marginLeft: '10px'}} >
                            <Button
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: transBG ? '#000' : '#fff', display: 'block', fontSize: '1.2rem' }}
                            >
                                {item.label}
                            </Button>
                        </div>
                    ))}
                </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}