import { Button, Container } from "@mui/material"
import { Link } from "react-router-dom"
import { FooterNew } from "./SinglePage"
import { Navbar } from "../components/Navbar"


export const NotFoundPage = () => {

    return (
        <div>
            <div style={{
                minHeight: '100vh'
            }} className="greenBG">
                <Navbar />
                <div style={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, zIndex: 1, overflow: 'hidden'}}>
                    <video 
                        style={{position: 'absolute'}}
                        id="videoPlayer"
                        loop playsInline autoPlay muted 
                        preload="none" className='dynVid'> 
                        <source type="video/mp4" src={`${process.env.PUBLIC_URL}/images/headervideo.mp4`} />
                    </video>

                    <div style={{zIndex: 9, display: 'flex', flexDirection: 'column', justifyContent: 'center', flexGrow: 1, position: 'relative'}}>
                        <Container style={{marginTop: '200px', backgroundColor: '#000c', borderRadius: '10px'}}>
                            <h2 style={{textAlign: 'center'}}>Page Not Found</h2>
                            <div style={{padding: '20px', display: 'flex',justifyContent: 'center'}}>
                                <Button variant="contained" LinkComponent={Link} to="/">Back To Home</Button>
                            </div>
                        </Container>
                    </div>
                </div>
            </div>
            <div style={{position: 'relative'}}>
                <FooterNew />
            </div>
        </div>
    )
}