import { makeid } from "../SinglePage"

export const NewRenderCategory = ({category, allItems, darkText}) => {
    // Services Portion
    if (darkText === undefined) {
        return OrderItems(allItems).filter(item => item.Category === category).map(item => {
                return (
                    <div style={{display: 'flex', color: '#fff', alignItems: 'center', justifyContent: 'center'}} key={`${item.Item}-${makeid(6)}`}>
                        <div style={{fontSize: '1rem', color: '#000'}}>{ item.Item }</div>
                        <div className='lineSepW' style={{padding: '0!important', marginTop: '10px'}}></div>
                        <div style={{color: '#000', fontWeight: 'bold'}}>{ item.Price }</div>
                    </div>
                )
            }
        )
    }

    // Others
    return OrderItems(allItems).filter(item => item.Category === category).map(item => {
            return (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px', color: 'inherit'}} key={`${item.Item}-${makeid(6)}`}>
                    <div style={{fontWeight: 'bold', fontSize: '1rem', color: darkText ? "#000" : "#fff"}}>{ item.Item }</div>
                    <div className='lineSepW' style={{padding: '0!important', marginTop: '10px'}}></div>
                    <div className='goldText' style={{fontWeight: 'bold', color: darkText ? "#000" : "#bf9e77"}}>{ item.Price }</div>
                </div>
            )
        }
    )
}



const OrderItems = (items) => {
    return items.sort((a, b) => parseInt(a.orderNumber) - parseInt(b.orderNumber));
}



export const WoofAllItems = [
    // Dog Walks 
    {Category: "Dog Walks", Item: "30 Minute Walk", Price: "$24"},
    {Category: "Dog Walks", Item: "60 Minute Walk", Price: "$35"},

    // Pet Sitting
    {Category: "HourlyPetSitting", Item: "Hourly Pet Sitting", Price: "$30"},
    {Category: "HourlyPetSitting", Item: "Multi-Hour Pet Sitting", Price: "$22"},

    // Cat Visits
    {Category: "Cat Visits", Item: "30 Minute Visit", Price: "$24"},
    {Category: "Cat Visits", Item: "Multiple Cat Visits", Price: "$22"},

    // Overnight Care
    {Category: "Overnight Care", Item: "In Sitter's Home", Price: "$85"},
    {Category: "Overnight Care", Item: "In Your Home", Price: "$95"},

    // PetChauffeur
    {Category: "Pet Chauffeur", Item: "One Way Trip", Price: "$24"},
    {Category: "Pet Chauffeur", Item: "Round Trip", Price: "$35"},

    // Additional Fees
    {Category: "Additional Fees", Item: "Last Minute Booking", Price: "$15"},
    {Category: "Additional Fees", Item: "Last Minute Cancellation", Price: "Full Cost"},
    {Category: "Additional Fees", Item: "After Hours Appointment", Price: "$8"},
    {Category: "Additional Fees", Item: "Holidays", Price: "$15"},
    {Category: "Additional Fees", Item: "Additional Pet", Price: "$7"},
    {Category: "Additional Fees", Item: "Weekend Fee", Price: "$8"},
]

/* Pricing Below Goes live May 1st */
export const WoofNewPricing = [
    // Dog Walks 
    {Category: "Dog Walks", Item: "30 Minute Walk", Price: "$30"},
    {Category: "Dog Walks", Item: "60 Minute Walk", Price: "$40"},

    // Pet Sitting
    {Category: "HourlyPetSitting", Item: "Hourly Pet Sitting", Price: "$30"},
    {Category: "HourlyPetSitting", Item: "Multi-Hour Pet Sitting", Price: "$22"},

    // Cat Visits
    {Category: "Cat Visits", Item: "30 Minute Visit", Price: "$30"},
    //{Category: "Cat Visits", Item: "Multiple Cat Visits", Price: "$22"},

    // Overnight Care
    {Category: "Overnight Care", Item: "In Sitter's Home", Price: "$95"},
    {Category: "Overnight Care", Item: "In Your Home", Price: "$105"},

    // PetChauffeur
    {Category: "Pet Chauffeur", Item: "One Way Trip", Price: "$30"},
    {Category: "Pet Chauffeur", Item: "Round Trip", Price: "$40"},

    // Additional Fees
    {Category: "Additional Fees", Item: "Additional Pet", Price: "$5"},
    {Category: "Additional Fees", Item: "Last Minute Cancellation", Price: "Full Cost"},
    {Category: "Additional Fees", Item: "Last Minute Booking", Price: "30% of Service"},
    {Category: "Additional Fees", Item: "After Hours Appointment", Price: "25% of Service"},
    {Category: "Additional Fees", Item: "Holidays", Price: "30% of Service"},
    {Category: "Additional Fees", Item: "Weekend Fee", Price: "20% of Service"},
]